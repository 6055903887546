import React, { useEffect, useState } from "react";
import {
    Button,
    Form,
    Input,
    Select,
    Spin,
    DatePicker,
    Modal,
} from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';

// API
import {
    addUser,
    checkIsEmailExist,
    getAllCenters,
} from "../../Constants/Api/functions/function";

// Assets
import MathsBg from '../../Assets/Images/MathsBg.jpg'
import LearnImg from '../../Assets/Images/signup.png'
import logo from '../../Assets/Logos/logo.png'

import './style.scss'

const { Option } = Select;

const Register = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [centers, setCenters] = useState(null)

    useEffect(() => {
        getCenters()
    }, [])

    const getCenters = async () => {
        setIsLoading(true)
        const res = await getAllCenters();
        if (res) {
            const centersCleaned = res.map((center) => ({
                value: center.id,
                label: center.name,
            }));
            setCenters(centersCleaned);
        }
        setIsLoading(false)
    }

    const checkEmail = async (email) => {
        const isEmailExist = await checkIsEmailExist(email);
        return isEmailExist;
    }

    const onFinish = async (values) => {
        const isEmailExist = await checkEmail(values.email);

        if (isEmailExist) {
            emailAlreadyExistsModal()
        } else {
            const user = {
                name: values.name,
                email: values.email,
                password: values.password,
                dateOfBirth: values.dateOfBirth.format('YYYY-MM-DD'),
                role: 'student',
                centerId: values.center,
                contactNumber: values.contactNumber,
                address: values.address,
                gender: values.gender,
            }

            await handleUserRegister(user)
        }
    };

    const handleUserRegister = async (user) => {
        try {
            setIsLoading(true)
            const res = await addUser(user);

            if (res && res.status === 201) {
                setIsLoading(false)
                successModal()

                setTimeout(() => {
                    window.location.href = '/login'
                }, 3000);

            } else {
                setIsLoading(false)
                errorModal()
            }
        } catch (error) {
            setIsLoading(false)
            errorModal()
        }

    }

    const errorModal = () => {
        Modal.error({
            title: 'Oops! Something went wrong',
            content: 'Please check the form and try again. If the problem persists, please contact the administrator.',
            className: 'register-modal'
        });
    }

    const successModal = () => {
        Modal.success({
            title: 'Successfully Registered',
            content: 'You have successfully registered. You will be redirected to the login page in a few seconds.',
            className: 'register-modal',
            onOk() {
                window.location.href = '/login'
            }
        });
    }

    const emailAlreadyExistsModal = () => {
        Modal.error({
            title: 'Error',
            content: 'The email address is already in use by another account, please try again with another email address.',
            className: 'register-modal'
        });
        getCenters();

    }

    return (
        <div className='signup-page'>
            <section className="bg-img">
                <div className="overlay">
                    <img src={MathsBg} alt="Maths Background" />
                </div>
                <div className="content">
                    <div className="logo">
                        <img src={logo} alt="" />
                    </div>

                    <h1>Welcome to</h1>
                    <span>
                        Micro:bit in Libraries
                    </span>

                    <h2>
                        Lets create your account
                    </h2>

                    <div className="learn-img">
                        <img src={LearnImg} alt="" />
                    </div>

                    <p className="quote">
                        "Education is the most powerful weapon which you can use to change the world"
                    </p>
                    <p className="by">
                        - Nelson Mandela -
                    </p>
                </div>
            </section>
            <section className="form">
                <div className="form-container">
                    <div className="logo-mobile">
                        <img src={logo} alt="" />
                    </div>
                    <h1 className="title">
                        Please fill the form to create your account
                    </h1>
                    <Spin spinning={isLoading}>
                        <Form
                            layout="vertical"
                            onFinish={onFinish}
                        >
                            <Form.Item
                                className='form-item'
                                name="name"
                                label="Name with Initials | මුලකුරු සමඟ නම | முதலெழுத்துக்களுடன் பெயர்"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your name with initials!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || value.length <= 50) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Name should be less than 50 characters!'));
                                        },
                                    }),
                                    {
                                        pattern: /^[A-Za-z\s.]*$/,
                                        message: 'Please use only English characters!',
                                    },
                                ]}
                            >
                                <Input placeholder="Enter your name with initials" />
                            </Form.Item>

                            <Form.Item
                                className='form-item'
                                label="Email Address | ඊ-මේල් ලිපිනය | மின்னஞ்சல் முகவரி"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Email address!',
                                    },
                                    {
                                        type: 'email',
                                    }
                                ]}
                            >
                                <Input placeholder="sampleuser@gmail.com" />
                            </Form.Item>

                            <Form.Item
                                className='form-item'
                                name="password"
                                label="Password | මුරපදය | கடவுச்சொல்"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your password',
                                    },
                                    { min: 8 },
                                ]}
                            >
                                <Input.Password
                                    placeholder="Input password"
                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </Form.Item>


                            <Form.Item
                                className='form-item'
                                label="Confirm Password | මුරපදය තහවුරු කරන්න | கடவுச்சொல் உறுதி செய்ய"
                                name="conPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The new password that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    placeholder="Input password"
                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </Form.Item>

                            <Form.Item
                                className='form-item'
                                name="dateOfBirth"
                                label="Date of Birth | උපන්දිනය | பிறந்த தேதி"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your date of birth!',
                                    },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>

                            <Form.Item
                                className='form-item'
                                name="address"
                                label="Home Address | නිවසේ ලිපිනය | வீட்டு முகவரி"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your home address!',
                                    },
                                    {
                                        pattern: /^[A-Za-z0-9\s.,/'()@]*$/,
                                        message: 'Please use only English characters, numbers, and special characters!',
                                    }
                                ]}
                            >
                                <Input placeholder="Enter your home address" />
                            </Form.Item>

                            <Form.Item
                                className='form-item'
                                label="Contact Number | දුරකථන අංකය | தொடர்பு எண்"
                                name="contactNumber"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your contact number!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || /^0[0-9]{9}$/.test(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The input is not a valid phone number (e.g., 0XX XXXX XXX)!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input placeholder="0XX XXXX XXX" />
                            </Form.Item>

                            <Form.Item
                                className='form-item'
                                name="center"
                                label="Library | පුස්තකාලය | நூலகம்"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your school!',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select a School"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={centers}
                                />
                            </Form.Item>

                            <Form.Item
                                className='form-item'
                                label="Gender | පුරුෂ භාවිතය | பால் பயன்பாடு"
                                name="gender"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select your gender!',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select your gender"
                                    allowClear
                                >
                                    <Option value="male">
                                        Male | පුරුෂ | ஆண்
                                    </Option>
                                    <Option value="female">
                                        Female | ගැහැණු | பெண்
                                    </Option>
                                </Select>
                            </Form.Item>

                            <Form.Item className='form-bottom'>
                                <Button className='form-button' type="primary" htmlType="submit"
                                >
                                    Register
                                </Button>

                                <div className="sign-up">
                                    <h1>Already have an account <a href="/login">Sign in</a></h1>
                                </div>
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </section>
        </div>
    )
}

export default Register